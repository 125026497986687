import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import FloatingFilterInput from '../../../../component/Common/Datagrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import FloatingFilterSelect from '../../../../component/Common/Datagrid/Component/FloatingFilterRenderer/FloatingFilterSelect';
import { CELL_TYPE_SWITCH } from '../../../../constant/Common/DataGrid/cellType';
import { SWITCH_OPTION_ACTIVE } from '../../../../constant/Common/Option/Switch';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';
import generateCellRenderer from '../../../Common/Datagrid/Generator/generateCellRenderer';

const createChassisColumnDefs = ({ mutatePatchActive, ...props }) => {
  const chassisSizeList = useSelector(state => state?.common?.chassisSizeList);
  const chassisTypeList = useSelector(state => state?.common?.chassisTypeList);

  /** Filter item */
  const { filterDivisionList, isEditable, disabledRef } = props;

  const safetyChassisColumn = [
    {
      headerName: 'DIV',
      field: 'div',
      editable: false,
      width: 38,
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: filterDivisionList,
      },
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'SIZE',
      field: 'size',
      editable: false,
      width: 46,
      valueFormatter: params => {
        return chassisSizeList.find(element => element?.size === params?.value)
          ?.size;
      },
      valueGetter: params => {
        return chassisSizeList.find(
          element => element?.size === params?.data?.size,
        )?.size;
      },
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'TYPE',
      field: 'type',
      editable: false,
      width: 77,
      valueFormatter: params => {
        return chassisTypeList.find(element => element?.type === params?.value)
          ?.type;
      },
      valueGetter: params => {
        return chassisTypeList.find(
          element => element?.type === params?.data?.type,
        )?.type;
      },
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'TARE (lb)',
      field: 'tare',
      editable: false,
      width: 68,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'right',
          },
        }),
    },
    {
      headerName: 'CHASSIS #',
      field: 'ch_no',
      editable: false,
      width: 90,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'VIN #',
      field: 'vin_no',
      editable: false,
      width: 160,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'ASSET TRACKER #',
      field: 'asset_tracker_no',
      editable: false,
      width: 126,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'PLATE #',
      field: 'plate_no',
      editable: false,
      width: 90,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'STATE',
      field: 'state',
      editable: false,
      width: 60,
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'REG EXP DATE',
      field: 'reg_exp_date',
      editable: false,
      width: 106,
      valueFormatter: params => {
        const data = params?.data?.reg_exp_date;
        return data && dayjs(data)?.isValid()
          ? dayjs(data).format('MM/DD/YY')
          : '';
      },
      valueGetter: params => {
        const data = params?.data?.reg_exp_date;
        return data && dayjs(data)?.isValid()
          ? dayjs(data).format('MM/DD/YY')
          : '';
      },
      comparator: dateComparator,
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: '90 DAYS DATE',
      field: '90_days_exp',
      editable: false,
      width: 106,
      valueFormatter: params => {
        const data = params?.data?.['90_days_exp'];
        return data && dayjs(data)?.isValid()
          ? dayjs(data).format('MM/DD/YY')
          : '';
      },
      valueGetter: params => {
        const data = params?.data?.['90_days_exp'];
        return data && dayjs(data)?.isValid()
          ? dayjs(data).format('MM/DD/YY')
          : '';
      },
      comparator: dateComparator,
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
    {
      headerName: 'ANNUAL EXP',
      field: 'annual_exp',
      editable: false,
      width: 100,
      valueFormatter: params => {
        const data = params?.data?.annual_exp;
        return data && dayjs(data)?.isValid()
          ? dayjs(data).format('MM/DD/YY')
          : '';
      },
      valueGetter: params => {
        const data = params?.data?.annual_exp;
        return data && dayjs(data)?.isValid()
          ? dayjs(data).format('MM/DD/YY')
          : '';
      },
      comparator: dateComparator,
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          styles: {
            textAlign: 'left',
          },
        }),
    },
  ];

  if (isEditable)
    safetyChassisColumn?.push({
      headerName: 'ACTIVE',
      field: 'active',
      editable: false,
      clickable: true,
      width: 75,
      valueFormatter: params => params.value,
      valueGetter: params => {
        return params?.data?.active ? 'Active' : 'Inactive';
      },
      cellRendererSelector: gridParam =>
        generateCellRenderer({
          gridParam,
          cellType: CELL_TYPE_SWITCH,
          value: gridParam?.data?.active,
          onChangeHandler: ({ ...params }) => {
            if (
              disabledRef?.current?.disabled &&
              disabledRef?.current?.disabledRow === gridParam?.rowIndex
            ) {
              return;
            }
            disabledRef.current.disabled = true;
            disabledRef.current.disabledRow = gridParam?.rowIndex;

            const { data } = gridParam;
            const { e, setChecked } = params;

            setChecked(!!e?.target?.checked);
            mutatePatchActive?.({ seq: data?.seq, setChecked });
          },
        }),
      floatingFilterComponent: FloatingFilterSelect,
      floatingFilterComponentParams: {
        option: SWITCH_OPTION_ACTIVE,
      },
    });

  return safetyChassisColumn;
};

export default createChassisColumnDefs;
