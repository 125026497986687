import FloatingFilterInput from '../../../../component/Common/Datagrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import {
  generateFormattedDay,
  generateFormattedTime,
} from '../../Common/Generator/formattedDayGenerator';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';

const createTrailerColumnDefs = () => {
  return [
    {
      headerName: 'DATE',
      field: 'in_out_date',
      width: 62,
      editable: false,
      valueGetter: params =>
        generateFormattedDay(params?.data?.in_out_date, 'MM/DD/YY'),
      comparator: dateComparator,
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
    },
    {
      headerName: 'TIME',
      field: 'in_out_time',
      width: 48,
      editable: false,
      valueGetter: params => generateFormattedTime(params?.data?.in_out_time),
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'time',
        maxLength: 5,
        placeholder: 'HH:mm',
      },
    },
    {
      headerName: 'YARD',
      field: 'yard',
      width: 70,
      editable: false,
      floatingFilter: false,
    },
    {
      headerName: 'LOC',
      field: 'yard_location',
      width: 72,
      editable: false,
    },
    {
      headerName: 'TRL #',
      field: 'trailer_no',
      width: 87,
      editable: false,
    },
    {
      headerName: 'SIZE',
      field: 'size',
      width: 46,
      editable: false,
    },
    {
      headerName: 'TYPE',
      field: 'type',
      width: 77,
      editable: false,
    },
    {
      headerName: 'SEAL #',
      field: 'seal',
      width: 150,
      editable: false,
    },
    {
      headerName: 'REMARKS',
      field: 'remarks',
      editable: false,
    },
  ];
};

export default createTrailerColumnDefs;
