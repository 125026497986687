import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import CommonButton from '../../../component/Common/Controller/Button/index';
import {
  PRESET_GREEN,
  PRESET_GREY_TRANSPARENT,
  PRESET_PRIMARY,
} from '../../../constant/Common/Style/preset';
import { PATH_COMMON_BUTTON_ICON } from '../../../constant/Common/Path/Asset/Icon/Button';
import AdvancedSearchModal from '../../../component/Safety/DamageReport/AdvancedSearchModal';
import CommonDataGrid from '../../../component/Common/Datagrid/index';
import createDamageColumnDefs from '../../../util/Safety/DamageReport/Generator/createDamageColumnDefs';
import DamageFormModal from '../../../component/Safety/DamageReport/DamageFormModal';
import exportToExcel from '../../../util/Common/Datagrid/Handler/exportToExcel';
import CommonAlert from '../../../component/Common/Modal/Alert/index';
import { ALERT_MODAL_STATUS } from '../../../constant/Common/Modal/Alert/status';
import clickOKButton from '../../../util/Safety/Common/Handler/Save/okButtonHandler';
import useGetDamageReportList from '../../../hook/Safety/DamageReport/Get/useGetDamageReportList';
import CommonLoading from '../../../component/Common/Loading';
import usePatchCompleted from '../../../hook/Safety/DamageReport/Patch/usePatchCompleted';
import handleRowClicked from '../../../util/Safety/DamageReport/Handler/handleRowClicked';
import useGetDamageReport from '../../../hook/Safety/DamageReport/Get/useGetDamageReport';
import { createDefaultModalValue } from '../../../util/Safety/DamageReport/Generator/defaultModalValueGenerator';
import usePatchDamage from '../../../hook/Safety/DamageReport/Patch/usePatchDamage';
import usePostDamageReport from '../../../hook/Safety/DamageReport/Post/usePostDamageReport';
import useControlLoadingStatus from '../../../hook/Safety/Common/Loading/useControlLoadingStatus';
import useGetGridFilterOption from '../../../hook/Safety/Common/Option/useGetGridFilterOption';
import useControlOption from '../../../hook/Safety/DamageReport/Control/useControlOption';
import { EDIT_MODE } from '../../../constant/Safety/Common/mode';
import checkPermission from '../../../util/Common/Router/Handler/checkPermission';
import { PERM_SAFETY_DAMAGE_REPORT_EDIT } from '../../../constant/Common/Permission';

const DamageReport = () => {
  /** useRef for handling disabled */
  const disabledRef = useRef({ disabled: false, disabledRow: null });

  /** Option Data List */
  const optionList = useControlOption();

  const isEditable = checkPermission({
    permission: PERM_SAFETY_DAMAGE_REPORT_EDIT,
  });

  /** DataGrid */
  const gridRef = useRef(null);

  /** Advanced Search Modal */
  const [isOpenSearchModal, setIsSearchModal] = useState(false);
  const [queryObject, setQueryObject] = useState({
    status: 'ALL',
    'from-date': dayjs()?.subtract(3, 'month')?.format('YYYY-MM-DD'),
    'to-date': dayjs()?.format('YYYY-MM-DD'),
  });

  /** Damage Report Form Modal */
  const [modalType, setModalType] = useState(EDIT_MODE?.add);
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);

  /** Alert Modal */
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    status: ALERT_MODAL_STATUS?.STABLE,
  });

  const setAlertOptions = { setIsOpenAlert, setAlertInfo };

  /** Get Damage Report List */
  const {
    damageReportList,
    isLoading: isLoadingDamageList,
    isFetching: isFetchingDamageList,
    isError: isErrorDamageList,
  } = useGetDamageReportList({ queryObject });

  /** Error Logic */
  useEffect(() => {
    if (isErrorDamageList) {
      setAlertInfo(prev => {
        return {
          status: ALERT_MODAL_STATUS.ERROR,
          message: 'Failed to fetch damage report list',
        };
      });
      setIsOpenAlert(prev => true);
    }
  }, [isErrorDamageList]);

  /** Get Damage Report */
  const {
    seq,
    setSeq,
    isCompleted,
    setIsCompleted,
    damageReportInfo,
    isLoading: isLoadingDamage,
    isFetching: isFetchingDamage,
  } = useGetDamageReport({ setAlertOptions });

  /** Post Damage Report */
  const { mutate: postDamageReportMutator, isPending: isPendingPostDamage } =
    usePostDamageReport({
      setAlertOptions,
      queryObject,
      setIsOpenFormModal,
      setSeq,
    });

  /** Patch Completed */
  const { mutate: completedPatchMutator } = usePatchCompleted({
    gridRef,
    disabledRef,
    setAlertOptions,
    setIsOpenFormModal,
    setSeq,
  });

  /** Patch Damage Report */
  const { mutate: damagePatchMutator } = usePatchDamage({
    gridRef,
    setAlertOptions,
    queryObject,
    setIsOpenFormModal,
    setSeq,
  });

  const [defaultValue, setDefaultValue] = useState(
    createDefaultModalValue({
      modalType,
      damageReportInfo,
      truckOptions: optionList?.truckOptions,
      chassisOptions: optionList?.chassisOptions,
    }),
  );

  /** Form */
  const damageReportController = useForm({
    defaultValues: defaultValue,
    shouldUnregister: true,
  });

  /** Setting Form Default Value */
  useEffect(() => {
    const newFormDefaultValue = createDefaultModalValue({
      modalType,
      damageReportInfo,
      truckOptions: optionList?.truckOptions,
      chassisOptions: optionList?.chassisOptions,
    });

    setDefaultValue(prev => newFormDefaultValue);
    damageReportController.reset(newFormDefaultValue);
  }, [
    modalType,
    damageReportInfo,
    optionList?.truckOptions,
    optionList?.chassisOptions,
  ]);

  useEffect(() => {
    if (!isOpenFormModal) {
      setSeq(null);
    }
  }, [isOpenFormModal]);

  /** Filter option */
  const { filterDivisionList } = useGetGridFilterOption();

  /** Loading */
  const { isLoadingOpen } = useControlLoadingStatus({
    isFetching: [isFetchingDamage],
    isLoading: [isLoadingDamageList, isLoadingDamage],
    isPending: [],
  });

  const [columnDefs, setColumnDefs] = useState(
    createDamageColumnDefs({
      completedPatchMutator,
      filterDivisionList,
      isEditable,
      disabledRef,
    }),
  );

  return (
    <>
      <div className="ml-[29px] flex flex-col flex-1 pb-8">
        <div className="mt-[20px] mb-[20px] flex justify-end gap-x-[10px] pr-[40px]">
          <CommonButton
            icon={PATH_COMMON_BUTTON_ICON.searchGrey}
            styles={PRESET_GREY_TRANSPARENT}
            onClick={() => setIsSearchModal(true)}
          >
            Search
          </CommonButton>
          <CommonButton
            styles={PRESET_GREEN}
            icon={PATH_COMMON_BUTTON_ICON?.excelGreen}
            onClick={() => {
              exportToExcel({ gridRef, fileName: 'DAMAGE REPORT' });
            }}
          >
            EXCEL
          </CommonButton>
          {isEditable && (
            <CommonButton
              styles={PRESET_PRIMARY}
              icon={PATH_COMMON_BUTTON_ICON?.plusBlue}
              onClick={() => {
                setIsOpenFormModal(prev => true);
                setModalType(prev => EDIT_MODE?.add);
                setIsCompleted(false);
              }}
            >
              Add
            </CommonButton>
          )}
        </div>
        <div className="w-full h-full pr-[40px]">
          {filterDivisionList?.length > 1 && (
            <CommonDataGrid
              gridRef={gridRef}
              columnDefs={columnDefs}
              data={damageReportList}
              onRowClicked={props =>
                handleRowClicked({
                  props,
                  setSeq,
                  setIsOpenFormModal,
                  setModalType,
                })
              }
            />
          )}
        </div>
      </div>
      <AdvancedSearchModal
        isOpenSearchModal={isOpenSearchModal}
        setIsSearchModal={setIsSearchModal}
        queryObject={queryObject}
        setQueryObject={setQueryObject}
        setAlertOptions={setAlertOptions}
      />
      {isOpenFormModal && (
        <DamageFormModal
          seq={seq}
          setSeq={setSeq}
          isOpenFormModal={isOpenFormModal}
          setIsOpenFormModal={setIsOpenFormModal}
          modalType={modalType}
          setAlertOptions={setAlertOptions}
          damagePatchMutator={damagePatchMutator}
          completedPatchMutator={completedPatchMutator}
          isCompleted={isCompleted}
          damageReportController={damageReportController}
          postDamageReportMutator={postDamageReportMutator}
          damageReport={defaultValue}
          optionList={optionList}
          isEditable={isEditable}
        />
      )}
      <CommonLoading isOpen={isLoadingOpen} />
      {isOpenAlert && alertInfo?.status !== ALERT_MODAL_STATUS?.SUCCESS && (
        <CommonAlert
          isOpen={isOpenAlert}
          setOpen={setIsOpenAlert}
          status={alertInfo?.status}
          content={alertInfo?.message}
          onOKButtonClick={() => {
            clickOKButton({
              setAlertOpen: setIsOpenAlert,
              setModalOpen: setIsOpenFormModal,
              status: alertInfo?.status,
            });
          }}
        />
      )}
    </>
  );
};

export default DamageReport;
